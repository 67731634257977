.contactUsForm {
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 30px 0px #0000001a;
    background-color: var(--white);
    position: relative;
  }
  
  .contactUsForm > form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .contactUsForm > form label {
    font-family: var(--bodyTextMedium);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0em;
    color: var(--text-primary);
  }
  
  .contactUsForm > form input, .contactUsForm > form select, .contactUsForm> form textarea{
    border: 1px solid var(--text-secondary);
    border-radius: 2px;
    padding: 8px;
  }
  
  .contactUsForm > form input::placeholder,
  .contactUsForm > form select {
    color: var(--text-secondary);
    font-family: var(--bodyTextMedium);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    width: 100%;
  }
  
  .submitBtn{
    align-items: center;
    background-color: var(--primary-btn-color);
    border: none;
    border-radius: 4px;
    color: var(--white);
    display: flex;
    gap: .5rem;
    padding: .5rem 1.5rem;
    float: right;
  }
  
  [lang="ar"] .submitBtn{
    float: left;
  }
  
  [lang="ar"] .contactUsForm>form label{
    text-align: right;
  }
  
  
  @media (min-width: 600px) {
    .contactUsForm {
      padding: 96px;
    }
  }
  
  @media (min-width: 992px) {
    .contactUsForm > form label {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.6875rem;
    }
    .contactUsForm > form input::placeholder,
    .contactUsForm > form select {
      font-size: 1.125rem;
      line-height: 1.6875rem;
    }
  } 
  .hideclass{ display: none;}
  .invalid > p{ 
    color:var(--dangerColor);
    margin-top: 8px;
  }
  .form-errors{display: none;}
  
  .form-group{
    display: flex;
    flex-direction: column;
  }