.primaryBtn {
  background-color: var(--primary-btn-color);
  border-radius: 4px;
  gap: 0.5rem;
  color: var(--white);
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 1rem;
}

.primaryBtn:hover{
  background-color: var(--primary-btn-hover-bg);
  text-decoration: none;
  color: var(--white);
}

@media (max-width: 992px) {
  .fullWidthBtn {
    width: 100%;
    justify-content: center;
    background: white;
    color: var(--primary-btn-color);
    position: relative;
    z-index: 5;
    bottom: -10px;
  }
}
  

[lang="ar"] .primaryBtn .chevronRight {
  transform: rotate(180deg);
}
