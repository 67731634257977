.ourExpertise {
    padding: 3rem 1.5rem;
    background-color: var(--white)
  }
  
  .ourExpertise > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    max-width: 1176px;
    margin: auto;
  }
  
  .ourExpertise > ul > li {   
    flex-basis: 40%;
  }
  
  .ourExpertise > ul > li > a{
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
    transition: transform .5s ease;
  }
  
  .ourExpertise > ul > li > a> img {
    width: 5rem;
    height: 5rem;
  }
  
  .ourExpertise>ul>li>a>h6 .ourExpertise>ul>li>a:hover>h6{
    color: var(--text-primary);
  }
  
  .ourExpertise>ul>li>a:hover{
    transform: translateY(-6px);
    text-decoration: none;
  }
  
  .ourExpertise>ul>li>a:hover>h6{
    color: var(--primary-btn-color)
  }
  
  .ourExpertise > ul > li > a>h6 {
    text-align: center;
    color:#000;
  }
  .ourExpertise .textContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .ourExpertise .heading {
    color: var(--text-primary);
  }
  .ourExpertise .description {
    color: var(--descriptionTextColor);
    font-size: 1.275rem !important;
    line-height: 1.7rem !important;
  }
  .ourExpertise .ourExpertiseSubHeading{
      max-width:835px;
      text-align:center;
      font-size: 1.01rem;
      line-height: 1.1rem;
      margin-bottom: 48px;
  }
  @media (min-width: 600px) {
    .ourExpertise > ul>li {
      flex-basis: 21%;
      padding: 0 8px;
    }
  }
  
  @media (min-width: 992px) {
    .ourExpertise > ul>li{
      flex-basis: 11%;
    }
  }
  .hasNoLink {
    cursor: default !important;
  }
  
  