/* Accordian Design */
:root{
  --faqBorder: rgba(29, 29, 29, 0.40);
}
.accordion_item {
  border-top: 1px solid var(--faqBorder) !important;
}
.accordianSectionWrapper .accordion_item:first-child{
  border-top: 0 !important;
}
.accordianSectionWrapper .accordion_item.active{
border-top: 0 !important;
}
.accordion_header {
  outline: none;
  border: 0;
  background: transparent;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 20px;
  border-radius: 12px 12px 0 0;
  text-align: start;
}
.accordion_header:focus
{ outline: none; box-shadow: none;}
.accordion_header.active {
  background-color: var(--faqBg);
}

.accordion_header_text {
  font-size: clamp(18px, 4vw, 24px);
  line-height: 130%;
  color: var(--headingColor);
  font-family: var(--headerFont);
}
.accordion_header_icon {
  background-color: var(--focus-color);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  border-radius: 4px;
  height: 32px;
  width: 32px;
  
}
.accordion_header .accordion_header_icon svg{
  transition: all 0.3s ease;
}
.accordion_header.active .accordion_header_icon svg
{
  transform: rotate(-180deg);
}
.accordion_body_wrapper
{
  transition: all 0.3s ease;
  max-height: 0;
  overflow: hidden;
}
.accordion_body_wrapper.show {
  max-height: 10000px;
}
.accordion_body {
  background-color: var(--faqBg);
  padding: 1rem 1.25rem;
}
/* About Us page Accordian Sections Desing */
.leadershipCardAccordianWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  column-gap: 20px;
  margin-bottom: 20px;
  row-gap: 20px;
}
/* Why ADAFZ page According Sections Design */
.aboutUsHeading{
  font-size: 36px;
  line-height: 130%;
}
.aboutUsParagraph{
  font-size: 20px;
  line-height: 150%;
  color:var(--textPrimaryColor);
}
 .blueLink {
 color: #1226AA;
}
.blueLink:hover{
  text-decoration: underline;
}
  /* .faqUniversalCardWpr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-bottom: 20px;
} */
.accodianBodyParagraph{
font-size: 16px;
line-height: 150%;
}
.imageWithListComponentSection{
padding: 40px 0;
}
.iconWithLongTextSection{
padding: 40px 0;
display: grid;
grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
column-gap: 24px;
}
.iconWithShortTextSection{
position: relative;
display: grid;
grid-template-columns: repeat(auto-fill, minmax(230px , 1fr));
column-gap: 24px;
padding: 40px 0;
}
.imageWithLongTextComponent img {
height: clamp(120px, 14vw, 160px);
}
/* Investor Service Accordian Design Sections Design */

.accordianSubHeading {
margin: 30px 0;
font-size: 22px;
line-height: 120%;
}

.linkList {
  position: relative;
  margin-bottom: 40px;
}
.linkList li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.linkList li a {
font-size: 18px;
text-decoration: underline;
color: var(--primary-btn-color);
line-height: 130%;
width: 100%;
display: grid;
grid-template-columns: 25px 1fr;
}
.visaServiceFAQPrimaryBTn {
  display: inline-block;
  margin: 40px 0;
}

@media (max-width: 490px) {
	.iconWithLongTextSection{
display:block;
}
.iconWithLongTextSection .imageWithLongTextComponent{
margin-bottom:1.5rem;
}
}