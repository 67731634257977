.universal-card{
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    position: relative;
    background: #fff;
}
.universal-card-ImageWpr {
    aspect-ratio: 4 / 3;
    height: 279px;
    display: flex;
    width: 100%;
    justify-content: center;
}
.universal-card-ImageWpr img {
    object-fit: cover;
    height: 100%;
    object-position: center;
    -o-object-position: center;
    min-height: 100%;
}
.universal-card:hover{
    box-shadow: 0px 16px 45px 0px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
    transition: all ease-out 100ms;
}

.universal-card:active{
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
}

.universal-card>img{
    aspect-ratio: 4/3;
}

.universal-card>.body{
    padding: 24px;
}

.content{
position: relative;
}

.label{
    color: var(--text-title, #AD841F);
    font-family: var(--bodyTextMedium);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 16.8px */
    text-align: start;
}

.title{
    color: var(--Text-text-primary, #282828);
    font-family: var(--headerFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 4px;
    text-align: start;
}

.body-md-reg{
    color: var(--text-secondary, #707070);
    font-family: var(--bodyTextRegular);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

.cta{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--Blue-button-primary-text, #1226AA);

/* Variable Type/Buttons/button-default */
font-family: var(--bodyTextMedium);
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 160%; /* 28.8px */
}


html[lang=ar] .cta svg {
    transform: scaleX(-1);
}
/* html[lang=ar] .title,
html[lang=ar] .body-md-reg
 {
    text-align: start;
} */
@media(min-width: 992px){ 
    .label{
        font-size: 16px;
    }
    
    .title{
        font-size: 18px;
    }
    
    .body-md-reg{
        font-size: 14px;
    }
    
    }
