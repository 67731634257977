.imageWithShortTextComponent {
    position: relative;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
}
.imageWithShortTextComponentText {
    font-size: clamp(14px, 4vw, 16px);
    line-height: 130%;
}
.imageWithShortTextComponent img {
    height: clamp(60px, 14vw, 80px);
}