.btnGroup {
    display: flex;
    gap: 8px;
  }
  
  @media (min-width: 992px) {
    .btnGroup {
      flex-direction: row-reverse;
    }
  }
  