@font-face {
    font-family: "SomarSansRegular";
    src: url("../fonts/SomarSans/SomarSans-Regular.otf");
  }
  
  @font-face {
    font-family: "SomarSansBold";
    src: url("../fonts/SomarSans/SomarSans-Bold.otf");
  }
  
  @font-face {
    font-family: "SomarSansMedium";
    src: url("../fonts/SomarSans/SomarSans-Medium.otf");
  }
  
  @font-face {
    font-family: "SomarSansLight";
    src: url("../fonts/SomarSans/SomarSans-Light.otf");
  }
  
  @font-face {
    font-family: "TiemposTextRegular";
    src: url("../fonts/TiemposFont/TiemposText-Regular.otf");
  }
  
  @font-face {
    font-family: "TiemposTextMedium";
    src: url("../fonts/TiemposFont/TiemposText-Medium.otf");
  }
  
  @font-face {
    font-family: "LaureenZazaRegular";
    src: url("../fonts/LAUREEN_ZAZA/LaureenZazaText-Regular.otf");
  }
  
  @font-face {
    font-family: "LaureenZazaMedium";
    src: url("../fonts/LAUREEN_ZAZA/LaureenZazaText-Medium.otf");
  }
  
  @font-face {
    font-family: "LaureenZazaBold";
    src: url("../fonts/LAUREEN_ZAZA/LaureenZazaText-Bold.otf");
  }
  