:root {
  --primary-color: #110c71;
  --secondary-color: #94a1f2;
  --focus-color: #e7e7f1;
  --tertiary-color: #5e72eb;
  --section-bg: #f2f2f2;
  --white: #ffffff;
  --text-primary: #282828;
  --text-secondary: #707070;
  --text-title: #ad841f;
  --primary-btn-color: #1226aa;
  --hover-bg: #E7E9F7;
  --primary-btn-hover-bg: #0C1971;
  --tertiary-border: #616EC6;
  --text-secondary: #707070;
  --box-shadow: #0000000d;
  --divider-bg: rgba(0, 0, 0, 0.175);
  --nav-bold: #ffffffcc;
  --cool-grey: #fbfbfb;
  --dangerColor: #f20707;
  --blackColor: #000000;
  --homeBannerOverlay: linear-gradient(180deg, rgba(17, 12, 113, 0.80) 0%, rgba(17, 12, 113, 0.24) 88.01%);
  --white:#ffffff;
  --textPrimaryColor:#1D1D1D;
  /* Font variables */
  --headerFont: "TiemposTextMedium";
  --bodyTextMedium: "SomarSansMedium";
  --bodyTextLight: "SomarSansLight";
  --enRegular: "SomarSansRegular";
  --tiemposRegular: "TiemposTextRegular";
  --btnSecondaryBg:#E7E9F7;
  --footerBg:#E3E4E5;
  --footerTextColor:#484848;
  --descriptionTextColor:#707070;
  --black:#000000;
  --veryLightGray:#fafafa;
  --faqBg:rgb(250 250 250 / 64%);
  --faqBorder:rgba(29, 29, 29, 0.40);
  --headingColor:#282828;
}

[lang="ar"] {
  --headerFont: "LaureenZazaBold";
}
