.partnerWithUs {
  background-color: var(--section-bg);
  padding: 4rem 1.5rem;
}

.innerContainer {
  max-width: 1176px;
  margin: auto;
}

.textContainer {
  text-align: center;
  margin-bottom: 2.5rem;
}

.textContainer > h3 {
  color: var(--text-primary);
}

.textContainer > p {
  color: var(--text-secondary);
}

.imageWrapper {
  display: grid;
  grid-template-columns: auto;
  gap: 1rem;
}

.imageWrapper>a{
  border-radius: 4px;
  overflow: hidden;
  transition: transform .5s ease;
}

.imageWrapper>a:hover{
  transform: translateY(-6px)
}


.imgDiv {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.imgText {
  position: absolute;
    left: 0;
    bottom: 0;
    padding: 56px 24px 24px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.81) 73.02%
    );
}


[lang="ar"] .textContainer>h3, [lang="ar"] .textContainer>p{
  text-align: center;
}

.imgText h2 {
  color: var(--white);
  text-align: center;
}

.imgText p{
  color: white;
  text-align: center;
  margin-top: 8px;
  transition: all 1s ease-out;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: var(--bodyTextRegular);
}

.imgText h2{
  transition: all .1s ease-out 2s;
}

[lang="ar"] .imgText h2{
  text-align: center;
}

.imgDiv>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 992px) {
  .partnerWithUs {
    padding-bottom: 0rem;
  }
  .imageWrapper>a {
    width: 100%;
    aspect-ratio: 16/9;
  }
  .imgText h2 {
    font-size: 24px;
    line-height: 28.8px;
  }
  .imgText p{
    font-size: 16px;
    line-height: 20.8px;
  }
}

@media(max-width:640px){
  .imageWrapper>a {
    width: 100%;
    aspect-ratio: 4/3;
  }
  .imgText h2 {
    font-size: 24px;
    line-height: 28.8px;
  }
  .imgText p{
    font-size: 16px;
    line-height: 20.8px;
  }
}

@media(min-width: 640px){
  .imageWrapper {
    grid-template-columns: auto auto;
    gap: 1.5rem;
  }
  .imgText h2 {
    font-size: 24px;
    line-height: 28.8px;
  }
  .imgText p{
    font-size: 16px;
    line-height: 20.8px;
  }
}

@media (min-width: 992px) {
  .partnerWithUs {
    padding: 5rem 8.25rem;
  }
  .imgText {
    padding: 3.5rem 1.5rem 1.5rem;
  }
  .imgText h2 {
    font-size: 26px;
    line-height: 31.2px;
  }
  .imgText p{
    max-height: 0;
    font-size: 19px;
    line-height: 24.7px;

  }
  .imageWrapper>a:hover .imgText p{
    max-height: 50px;
    }
}
