.imageWithListComponent {
    display: flex;
    column-gap: 24px;
    margin-bottom: 40px;
}
.imageWithListComponent_list {
    font-size: clamp(14px, 4vw, 18px);
    line-height: 150%;
    position: relative;
    display: flex;
    align-items: center;
}

:is(.imageWithListComponent_title) {
    /* max-inline-size: initial; */
    margin-block-end: 20px;
}
.imageWithListComponent_list span {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageWithListComponent_img_wpr img {
    height: clamp(100px, 14vw, 162px);
    border-radius: 8px;
}
@media (max-width: 768px) {
    .imageWithListComponent {
        display: flex        ;
        flex-direction: column;
        column-gap: 0;
        row-gap: 20px;
        margin-bottom: 40px;
    }
  }
  @media (max-width: 490px) {
    .imageWithListComponent_img_wpr img {
        height: auto;
        width: 100%;
        
    }
    }