.homeBannerComponent.homeBanner {
  aspect-ratio: 3/4;
  position: relative;
}

.homeBannerComponent.homeBanner .textContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 82px 0 5rem;
}

.homeBannerComponent.homeBanner::after {
  position: absolute;
  content: "";
  background: var(--homeBannerOverlay);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.homeBannerComponent.homeBanner > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homeBannerComponent.homeBanner .textContainer > h1 {
  color: var(--white);
  position: relative;
  z-index: 3;
  top: 0;
  max-width: 90%;
  margin: auto;
  text-align: center;
}

@media (min-width: 600px) {
  .homeBannerComponent.homeBanner {
    aspect-ratio: 4/3;
  }
}

@media (min-width: 992px) {
  .homeBannerComponent.homeBanner {
    aspect-ratio: 28 / 9;
  }
  .homeBannerComponent.homeBanner .textContainer {
    padding: 0 0 5rem;
  }
  .homeBannerComponent.homeBanner .textContainer > h1 {
    max-width: 688px;
  }
}

[lang="ar"] .homeBannerComponent.homeBanner > img {
  transform: scaleX(-1);
}
@media(max-width: 490px){
 
  .homeBannerComponent.homeBanner {
      aspect-ratio: 1 / 1;
      position: relative;
     }
     .homeBannerComponent.homeBanner .textContainer>h1 {
      line-height: 160%;
  }
  }