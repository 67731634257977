.secondaryBtn {
    background-color: var(--btnSecondaryBg);
    border-radius: 4px;
    gap: 0.5rem;
    color: var(--primary-btn-color);
    border: none;
    display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 1rem;
  border: 1px solid var(--white);
  }

  .secondaryBtn:hover{
    color: var(--primary-btn-color);
    background-color: var(--hover-bg);
    text-decoration: none;
    border: 1px solid var(--tertiary-border);
  }
  