.overlay {
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  opacity: 0;
  background: var(--text-color);
  overflow-x: hidden;
  transition: .3s;
  opacity: .3;
}
.ddTitle{
  position:fixed;
  left: 1rem;
}
.langSelector {
  flex-grow: 0 !important;
} 
.navLinkSeparator {
  border: 1px solid var(--white);
  opacity: 0.8;
  height: 24px;
  width: 1px;
  margin-top: .375rem;
}
.headerNav{
  /* margin-top: 1.5rem; */
}
.headerNav a,
.headerNav a:hover{
  color: var(--white) ;
}
.dropdown-item{
  color: var(--primary-btn-color);
}
.nav-link{
  color: var(--white) !important;
}
.searchBox{    
  left: 0;
  width: 100%;
}
.searchBox > div {
  max-width: 40rem;
  margin: 0 auto;
  gap: .5rem;
}
.searchField, .searchField:hover, .searchField:active, .searchField:focus{
  padding: .5rem !important;
  box-shadow: none !important;
  outline: none !important;
  border-color: var(--text-secomdary) !important;
}
.searchBtn, .searchBtn:hover, .searchBtn:active, .searchBtn:focus{
  background-color: var(--primary-btn-color) !important;
  color: var(--white) !important;
  padding: .5rem 1rem !important;
  border-radius: .375rem;
  border: none;
  box-shadow: none;
  outline: none;
  width: auto !important;
}
.suggestionBox{
  display: none;
  position: absolute;
  right: 4rem;
  top: 2.75rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px 0px #0000004a;
  padding: 0.25rem;
  min-width: calc(100% - 4.125rem);
}
.ddSuggestions{
  margin-top: .25rem;
}
.ddSuggestions li{
  border-bottom: 1px solid var(--divider-bg);
}
.ddSuggestions li:last-child{
  border-bottom: 0 none;
}
.ddSuggestions li a, .ddSuggestions li a:hover, .ddSuggestions li a:active{
  display: inline-block;
  color: var(--primary-color);
  padding: .5rem;
  width: 100%;
}
.ddSuggestions li a:hover{
  background-color: var(--focus-color);
}
html[lang='ar'] .headerNav img {
  transform: scaleX(-1);
}
html[lang='ar'] .ddTitle{
  left: unset;
  right: 1rem;
}
html[lang='ar'] .inputCover::after{
  right: unset;
  left: 1.25rem;
  transform: scaleX(-1);
}
html[lang='ar'] .ddSuggestions li a {
  direction: rtl;
  text-align: right;
}
[lang="ar"] .suggestionBox{
  right: unset;
}
@media screen and (max-width: 991px) { 
  #passengerLink.hide{
    display: none;
  } 
  .navLinkSeparator {
      display: none;
  }
  .headerNav{
      padding: 0 .75rem;
      margin-top: 0;
  }        
  .ddTitle{
      display:none;
  }
  .langSelector {
      margin-top : 0;
  } 
  .searchBox{
      top: 3.5rem !important;
      padding: 1rem !important;
      border-radius: 0 0 0.25rem 0.25rem !important;
  }
  .suggestionBox{
      position: fixed;
      width: 100%;
      right: 0;
      top: 3.25rem;
  }
  .ddSuggestions li a{
      margin: 0;
  }
}

