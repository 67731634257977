@import url("./css/variables.css");
@import url("./css/_fonts.css");

html{
  overscroll-behavior: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  padding: 0;
  margin: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
}

html[lang="ar"] body {
  direction: rtl;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headerFont);
  font-weight: 500;
}

[lang="ar"] h1 {
  font-weight: 700;
}

[lang="ar"] h2 {
  font-weight: 700;
}

[lang="ar"] h3 {
  font-weight: 700;
}

[lang="ar"] h4 {
  font-weight: 700;
}

[lang="ar"] h5 {
  font-weight: 700;
}

[lang="ar"] h6 {
  font-weight: 700;
}

body {
  font-family: var(--enRegular);
  background-color: var(--section-bg);
  font-size: 14px;
}

h1 {
  font-size: 1.75rem;
  line-height: 2.1rem;
}

h3 {
  font-size: 1.55rem;
  line-height: 1.8rem;
}

h4 {
  font-size: 1.375rem;
  line-height: 1.65rem;
}

h5 {
  font-size: 1.375rem;
  line-height: 1.625rem;
  letter-spacing: 0em;
}

h6 {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  letter-spacing: 0em;
}

p.x-large {
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.5625rem;
  letter-spacing: 0em;
  font-family: var(--bodyTextLight);
}

.x-large-bold {
  font-family: var(--bodyTextLight);
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 2.0625rem;
}

p.large-reg {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3125rem;
  letter-spacing: 0em;
  font-family: var(--bodyTextLight);
}

p.medium-reg {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0em;
  font-family: var(--bodyTextLight);
}

p.micro-reg {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.975rem;
  letter-spacing: 0em;
  font-family: var(--bodyTextLight);
}

.btn-default {
  font-size: 1rem;
  font-weight: 700;
  font-family: var(--bodyTextMedium);
}

.nav-bold {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: 0em;
  font-family: var(--enRegular);
}
.navbar {
  background-color: var(--primary-color) !important;
  transition: top 0.2s, opacity 0.2s, background-color 0.2s, z-index 0.2s;
  transition-timing-function: ease-in-out;
  align-items: center;
  top: 0;
}
.navbar.scrollDn {
  top: -1rem;
  opacity: 0;
  z-index: -1;
}
.navbar.scrollUp {
  top: 0;
  opacity: 1;
  z-index: 1030;
}
.navbar.scrollUp.stickTop{
  top: 0
}
.navbar-nav .dropdown-menu{
  min-width: 100%;
}
.navbar-nav .nav-link {
  padding: 0.5rem;
  border-bottom: 1px solid var(--divider-bg);
}
.navbar-nav .nav-link.dropdown-toggle.show {
  background-color: var(--focus-color);
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-item {
  color: var(--primary-btn-color) !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}
.navbar-brand {
  margin-left: 1rem;
  outline:none;
  text-decoration: none;
}
.navbar.reverse,
.navbar.reverseBG,
.bgTransparent.off.reverse,
.bgTransparent.off.reverseBG {
  background-color: var(--white) !important;
  box-shadow: 0px 0px 10px 0px #0000004a;
}
.navbar.reverse svg path,
.navbar.reverseBG svg path {
  fill: var(--primary-color);
}
.navbar.reverse a,
.navbar.reverseBG a {
  color: var(--primary-color) !important;
}
.navbar.reverse .navbar-nav div,
.navbar.reverseBG .navbar-nav div {
  border-color: var(--primary-color);
}
.navbar-toggler{
  position: absolute;
  right: 1rem;
  top: 0.5rem;
}
.navbar-nav .nav-link,
.navbar-toggler {
  outline: 0 none;
  box-shadow: none !important;
}
.navbar-toggler .navbar-toggler-icon {
  background-image: url(../assets/images/hamburger.svg);
}
.navbar.reverse .navbar-toggler .navbar-toggler-icon,
.navbar.reverseBG .navbar-toggler .navbar-toggler-icon {
  background-image: url(../assets/images/hamburger-blue.svg);
}
.DDHead {
  left: 1rem;
  top: 7.5rem;
}
.container {
  max-width: 1176px;
}
.slim-body {
  max-width: 768px !important;
  margin: auto;
  padding: 0 1.5rem;
}
.bodyBox {
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 0px 0px 30px 0px #0000001a;
  background-color: var(--white);
  position: relative;
}
.slick-next:before,
.slick-prev:before {
  color: black !important;
}
.textImg {
  width: 64%;
  margin-bottom: 1rem;
}
.slick-slider {
  padding-top: 1.5rem;
  margin-top: 0 !important;
}
.slick-slider .slick-slide img {
  width: 100%;
}
.btn-default img {
  width: auto !important;
}
.btn.active {
  background-color: var(--white) !important;
}
/*Global Classes*/
.fixedBGTop {
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 100%;
}
.bgColor {
  position: relative;
  background-color: var(--section-bg);
}
.bgColorPrimary {
  background-color: var(--primary-color);
  color: var(--white);
}
.colorPrimary {
  color: var(--primary-color) !important;
  text-decoration: none;
}
.colorPrimary svg path {
  fill: var(--primary-color);
}
.colorSecondary {
  color: var(--secondary-color);
  text-decoration: none;
}
.colorSecondary svg path {
  fill: var(--secondary-color);
}
.sectionHead {
  color: var(--white);
}
.comTitle {
  color: var(--text-title);
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 0.5rem;
}
.dirName {
  color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 110%;
}
.card {
  overflow: hidden;
  min-height: 420px;
}
.card-text {
  margin-top: 0.5rem;
}
.cardImg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
}
.cardImg img {
  width:100%;
  visibility: hidden;
}
.cardImg svg {
  position: absolute;
  width: 6.75rem;
  height: 5.25rem;
}
.moreLink {
  display: inline-block;
  color: var(--primary-btn-color);
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  margin-top: 0.5rem;
  vertical-align: middle;
  position: relative;
}
.moreLink svg {
  position: relative;
  top: -1px;
}
.moreLink svg path {
  fill: var(--primary-btn-color);
}
.moreLink.colorWhite {
  color: var(--white);
}
.moreLink.colorWhite svg path {
  fill: var(--white);
}
.moreLink svg,
.inlineImg {
  vertical-align: inherit;
}
.bgCurve-top,
.bgCurve-bottom {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: -1px;
}
.bgCurve-top img,
.bgCurve-bottom img {
  width: 100%;
  max-height: 100%;
}
.bgCurve-bottom {
  top: unset;
  bottom: -1px;
  overflow: hidden;
}
.headerBG {
  position: fixed;
  background-color: var(--primary-color);
  height: 7.5rem;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1029;
}
.headerMargin {
  padding-top: 6rem;
}
.bgDiv {
  background-color: var(--primary-color);
}
.sliderHead {
  position: relative;
  color: var(--white);
  padding-top: 5rem;
  padding-bottom: 1rem;
}
.slick-slide .row {
  display: flex !important;
}
.slick-dots li button:before {
  color: var(--secondary-color) !important;
}
.sliderBox::after{
  display: block;
  content: "";
  clear: both;
}
.sliderBox .slick-dots {
  float: left;
  width: auto;
  padding: 0 .5rem;
}
.sliderBox .slick-dots li, .sliderBox .slick-dots li button {
  height: unset;
  width: unset;
  padding: 0;
}
.sliderBox .slick-dots li button::before {
  background-color: var(--secondary-color);
  width: .5rem !important;
  height: .5rem !important;
  position: static;
  content: "";
  display: block;
  border-radius: .5rem;
  opacity: 1;
}
.sliderBox .slick-dots li.slick-active button::before {
  background-color: var(--tertiary-color);
  width: 1.25rem !important;
}
.sliderBox.singleCard .slick-cloned{
  display: none;
}
.inputCover{
  width:0;
  position: relative;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s, z-index 0.5s, width .5s ease-in;
}
.inputCover.show{
  width: 224px;
  z-index: 0;
  visibility:visible;
  opacity: 1;
}
.contentList {
  list-style: disc;
  margin-left: 0.25rem;
}
.contentListNum {
  list-style: decimal;
  margin-left: 0.25rem;
}
.contentList li,
.contentListNum li {
  margin-left: 1rem;
  padding-left: 0.25rem;
}
nav {
  display: none;
}
.slick-dots {
  text-align: left !important;
  bottom: -16px;
}
.arrowImg {
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.arrowRight {
  position: absolute;
  top: -66px;
  right: 14px;
  z-index: 100;
  cursor: pointer;
}
.arrowLeft {
  position: absolute;
  top: -66px;
  right: 60px;
  z-index: 100;
  cursor: pointer;
}
.carouselArrows {
  display: flex;
  align-items: center;
  gap: 13px;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  padding: 0 0.5rem;
}
.carouselArrows > img {
  cursor: pointer;
}
.slick-dots li button:before {
  color: #94a1f2 !important;
  width: 30px !important;
  height: 30px !important;
}
.slick-dots {
  position: relative;
}

.previewTile{
  border-radius: 8px;
  overflow: hidden;
  height: 158px;
  position: relative;
}

.previewTile:hover{
  cursor: pointer;
  transform: translateY(-4px);
}

[lang="ar"] .previewTile>img{
  transform: scaleX(-1);
}

@media(max-width: 500px){
  .previewTile{
    height: 98px;
  }
}

@media(max-width: 768px){
  .previewTile{
      width: 100%;
  }
  .previewTile>img{
      width: 100%;
  }
}

.centered-body-sm {
  max-width: 776px;
  margin: auto;
  padding: 0 1.5rem;
}
.centered-body-sm {
  max-width: 776px;
  margin: auto;
  padding: 0 1.5rem;
}

.centered-body-wide {
  max-width: 1176px;
  margin: auto;
  padding: 0 1.5rem;
}
.bannerImg {
  width: 100%;
  border-radius: 10px;
  max-height: 504px;
}
.btnGroup {
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px 0px #0000004a;
  padding: 0.25rem;
}
.btnGroup .btn {
  margin: 0 0.125rem;
  min-width: 30%;
}
.btnGroup .btn-primary {
  background-color: var(--primary-btn-color);
}

.five-cards-row{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  justify-content: left;
  row-gap: 20px;
  padding: 20px 0 32px;
  column-gap: 16px;
}
.five-cards-row > .card-col{
  width: 100%;
}
[lang="ar"] .five-cards-row{
  justify-content: right;
}


/*AR Styling*/
html[lang="ar"] p,
html[lang="ar"] h1,
html[lang="ar"] h2,
html[lang="ar"] h3,
html[lang="ar"] h4,
html[lang="ar"] h5,
html[lang="ar"] h6,
html[lang="ar"] ul,
html[lang="ar"] li {
  direction: rtl;
}
html[lang="ar"] .slick-dots,
html[lang="ar"] .dropdown-item {
  text-align: right !important;
}
html[lang="ar"] .bgCurve-top,
html[lang="ar"] .bgCurve-bottom,
html[lang="ar"] .inlineImg,
html[lang="ar"] .moreLink svg,
html[lang="ar"] .nav-link svg,
html[lang="ar"] .arrowImg,
html[lang="ar"] .colorSecondary svg {
  transform: scaleX(-1);
}
html[lang="ar"] .comTitle,
html[lang="ar"] .dirName,
html[lang="ar"] {
  direction: rtl;
}
html[lang="ar"] .contentList,
html[lang="ar"] .contentListNum {
  margin-left: 0;
  margin-right: 0.25rem;
}
html[lang="ar"] .contentList li,
html[lang="ar"] .contentListNum li {
  margin-left: 0;
  padding-left: 0;
  margin-right: 1rem;
  padding-right: 0.25rem;
}

[lang="ar"] .slick-dots {
  text-align: right !important;
}
.slick-dots li.slick-active button:before {
  color: #ffffff !important;
}
html[lang="ar"] .dropdown-toggle::after {
  margin-right: 0.255rem;
}
html[lang="ar"] .arrowRight {
  right: unset;
  left: 60px;
}
html[lang="ar"] .arrowLeft {
  right: unset;
  left: 9px;
}
html[lang="ar"] .col.text-end {
  text-align: left !important;
}
html[lang="ar"] .textImg.float-end {
  float: left !important;
}
html[lang="ar"] .carouselArrows > img {
  transform: rotate(180deg);
}
html[lang="ar"] .navbar-toggler {
  right: unset;
  left: 0;
}
html[lang="ar"] .form-select {
  padding-right: .75rem;
  padding-left: 2.25rem;
  background-position: left 0.75rem center;
}
html[lang="ar"] .sliderBox .slick-dots{
  float: right;
}
html[lang="ar"] .carouselArrows{
  right: unset;
  left: 1rem;
}
/* ////////////mobile ////// */

/*Media Quries*/


@media (min-width: 992px) {
  h1 {
    font-size: 3rem;
    line-height: 4rem;
  }
  h2 {
    font-size: 1.625rem;
    line-height: 1.9375rem;
    letter-spacing: 0em;
  }
  h3 {
    font-size: 2.5rem;
    line-height: 3.25rem;
  }
  h5 {
    font-size: 1.5rem;
    line-height: 120%;
  }
  p.x-large {
    font-size: 1.375rem;
    line-height: 2.06rem;
  }

  p.large-reg {
    font-size: 1.05rem;
    line-height: 130%;
  }
  p.medium-reg {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  p.micro-reg {
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
  .btn-default {
    font-size: 1.125rem;
  }
  .headerMargin {
    padding-top: 8.5rem !important;
  }
  .bodyBox {
    padding: 6rem;
  }
  .navbar-nav .nav-link {
    padding: 0.5rem 0.6875rem !important;
    border-bottom: 0 none;
  }
  li.nav-item {
    display: flex;
    align-items: center;
}
.nav-item-seperator {
  background: var(--veryLightGray);
  width: 1px;
  display: block;
  height: 100%;
  max-height: 20px;
  opacity: .35;
}
.reverse .nav-item-seperator{
  background: var(--primary-color);
}
  .navbar-nav .nav-link.dropdown-toggle.show {
    padding-top: 2.5rem !important;
    padding-bottom: 2.125rem !important;
    margin-top: -2rem;
    margin-bottom: -2.125rem;
  }
  .navbar.reverse::before {
    position: absolute;
    top: 6.125rem;
    content: "";
    width: 100%;
    height: 1px;
    border-top: 1px solid var(--divider-bg);
  }
  .slick-initialized .slick-slide {
    padding: 0 0.75rem;
  }
  .slick-slider.no-infinite-scroll .slick-track, .slick-slider.no-infinite-scroll .slick-list {
    transform: translate3d(0, 0, 0)!important;
  }
  html[lang="ar"] .float-lg-end{
    float: left !important;
  }
}
@media screen and (min-width: 1240px) {
  .navbar-nav .nav-link {
    padding: 0.5rem 1.375rem !important;
  }
  .navbar-nav .nav-link:hover {
    background: (--focus-color);
  }
}
@media screen and (max-width: 575px) {
  .headerBG {
    height: 5.5rem;
  }
  .btnOuter {
    flex-direction: row-reverse;
  }
  .detailBox {
    box-shadow: none !important;
    border: 0 none !important;
    background-color: transparent !important;
  }
  .sliderHead {
    margin-top: 2rem;
    padding-top: 8.5rem;
  }
  .sliderHead h3 {
    text-align: center;
  }
  .sliderArrow {
    display: none !important;
  }
  .bgCurve-bottom img {
    height: 14rem;
  }
  .slick-slider {
    padding-top: 0.5rem;
  }
}
@media screen and (min-width: 768px) {
  .bgTransparent {
    background-color: transparent !important;
  }
  .bgTransparent.off {
    background-color: var(--primary-color) !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    border-width: 0;
    border-radius: 0;
    top: 4.125rem;
  }
  .navbar-collapse {
    align-items: flex-start;
  }
  .btnGroup {
    text-align: center;
  }
  .btnGroup .btn {
    min-width: 6.5rem;
  }
 
}
@media screen and (max-width: 991px) {
 
  .container {
    padding: 0 1.55rem;
  }
  .DDHead {
    top: 6rem;
  }
  .bgTransparent {
    background-color: transparent !important;
  }
  .bgTransparent.off {
    background-color: var(--primary-color) !important;
}
  .navbar-toggler{
    border-color: transparent !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin: 0 0 0 0 !important;
    border: 0 none;
  }
  .navbar-nav .nav-link {
    padding: 0.75rem 0.5rem;
  }
  .navbar-nav .nav-link:last-of-type {
    border-top: 0 none;
    width: 100%;
  }
  
  .exLinks {
    position: absolute;
    right: 2.5rem;
    margin-top: 0;
  }
  html[lang="ar"] .exLinks {
    right: unset;
    left: 2.5rem;
  }
  .exLinks ul.navbar-nav {
      display: flex;
      flex-direction: row;
  }    
  .exLinks ul.navbar-nav a {
      border: 0 none !important;
      margin: 0 .25rem;
  }
  .dropdown-divider {
    margin: .25rem 0;
  }  
  .carouselArrows {
    display: none;
  }
  .slick-slide > div {
    padding: 0.5rem;
    position: relative;
    left: -3rem;
    width: calc(100% - 44px);
  }
  .slick-slide.slick-current > div {
    left: 0;
  }
  .textImg {
    width: 100%;
    margin: 1rem 0;
  }
  html[lang="ar"] .slick-slide > div {
    left: unset;
    right: -1rem;
  }
  html[lang="ar"] .slick-slide.slick-current > div {
    left: unset;
    right: -0.5rem;
  }
  .slick-slider {
    padding-right: 0;
  }
  .slick-dots,
  [lang="ar"] .slick-dots {
    text-align: center !important;
  }
  .sliderBox .slick-dots {
    float: none;
    top: 0;
    left: -1.5rem;
  }
  .sliderBox.singleCard .slick-dots {
    left: auto;
  }
  .sliderBox .moreLink.colorWhite {
    display: block;
    text-align: center;
  }
  .centered-body-wide .slider-container,
  .container .slick-slider,
  .centered-body-wide .slick-slider {
    margin-right: -1.5rem;
  }
  .container.sliderBox.singleCard .slick-slider{
    margin-right: 0;
  }
  .container.sliderBox.singleCard .slick-slider .slick-slide.slick-active > div{
    left: unset;
    margin: 0 auto;
  }
  .inputCover.show {
    width: 200px;
  }
}
@media screen and (max-width: 1240px) {
  .nav-item.hide{
    display: none !important;
  }
}



.fixed-body{
  overflow: hidden;
}

.modal-backdrop{
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: black;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: .5;
}

.gallery-modal{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 2000;
  border-radius: 4px;
  width: 70%;
  overflow: hidden;
  background-color: var(--white);
}


.modal-header{
  padding: 12px;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-icons{
  display: flex;
  gap: 4px;
}

.modal-header img{
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.modal-body{
  height: 40vh;
  position: relative;
}

.grid-lg-3-md-2{
  display: grid;
  grid-template-columns: auto;
  row-gap: 1rem;
  column-gap: 1rem;
}
.accordion .accordion-button:first-child {
  border-top: 1px solid var(--faqBorder);
  border-radius: 0;
  
}
.accordion-button, .accordion-item{
  background-color: transparent;

}
.accordion-button{
  border-radius: 0;
}
.accordion-button:focus{
  border-color:transparent;
  outline: none;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background-color: var(--faqBg) !important;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
  border-radius:  12px 12px 0 0 ;
}
.accordion .accordion-button:not(.collapsed):first-child {
  border-top: 0;
  border-radius:  12px 12px 0 0 ;
}
.accordion .accordion-item:not(:first-child) .accordion-button:not(.collapsed){
  border-radius: 0 !important;
  border-top: 1px solid var(--faqBorder) !important;
}

.accordion-button::after {
  background-color: var(--focus-color);
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  border-radius: 4px;
  height: 32px;
  width: 32px;
}
[lang="ar"] .accordion-button::after {
  position: absolute;
  left: 0;
}
.accordion-button p
{
  font-size: clamp(20px, 4vw, 24px);
    line-height: 130%;
}


.accordion-item {
  border: 0 !important;
  border-radius: 0 !important;
  overflow: visible;
}
.accordion .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-body{
  background-color:var(--faqBg);
}
@media(min-width:768px){
  .grid-lg-3-md-2{
    grid-template-columns: auto auto;
  }
}

@media(min-width: 992px){
  .modal-body{
      height: 70vh
  }
  .grid-lg-3-md-2{
    grid-template-columns: auto auto auto;
  }
}


@media(max-width:1024px){
  .five-cards-row{
    column-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}
}
@media(max-width:992px){
  .five-cards-row{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media(max-width:768px){
  .five-cards-row{
    grid-template-columns: 1fr 1fr 1fr ;
  }
  .navbar-nav .nav-item:last-child .nav-link {
    border-bottom: 0;
  }
  p.x-large {
    font-size: 18px !important;
    }
}
@media(max-width:490px){
  .five-cards-row{
    grid-template-columns: 1fr 1fr ;
  }
}
.bgCurve-top img {
  max-height: 455px;
}
/* .navbar-nav .nav-link[title] {
  display:none !important;
} */



